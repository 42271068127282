import actions from "@/base/store/actions.js";
import actionsTeacher from "@/base/actions/teacher.js";
import actionsTeacherClass from "@/base/actions/teacher-class.js";
import actionsTeacherClassReloader from "@/base/actions/teacher-class-reloader.js";
import actionsTeacherClassDailyJuices from "@/base/actions/teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "@/base/actions/teacher-class-assignments.js";
import actionsTeacherClassAchievements from "@/base/actions/teacher-class-achievements.js";

import monitorCommands from "@/app/monitors/commands.js";

import api from "@/app/api.js";


const MONITOR_COMMANDS = monitorCommands.getMonitorCommands();

function getTeacherCallbacks(store) {
    const services = { api, actions };

    return {
        reloadDashboard() {
            store.dispatch(actionsTeacher.reloadDashboard(services));
        },

        reloadClass() {
            store.dispatch(actionsTeacherClassReloader.reloadClass(services));
        },

        reloadClassTeachers() {
            store.dispatch(actionsTeacherClass.reloadClassTeachers(services));
        },

        reloadClassDailyJuicesScores() {
            store.dispatch(actionsTeacherClassDailyJuices.loadOrReloadDailyJuicesScores(services));
        },

        reloadClassAssignmentsScores() {
            store.dispatch(actionsTeacherClassAssignments.loadOrReloadAssignmentsScores(services));
        },

        reloadClassStudentAchievements() {
            store.dispatch(actionsTeacherClassAchievements.loadOrReloadStudentsAchievements(services));
        },

        setUserBadEmail() {
            store.dispatch(actions.user.setIsBadEmail(true));
        },

        setStoryRating(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryRate(data));
        },

        setExtraJuiceRating(data) {
            store.dispatch(actions.juiceStories.setStoryInfoStoryExtraJuiceRate(data));
        },

        /* --- */

        onUpdate(data) {
            if (data.cmd === MONITOR_COMMANDS.DJ_IN_PROGRESS
                || data.cmd === MONITOR_COMMANDS.DJ_COMPLETED) {
                this.reloadDashboard();
            } else if (data.cmd === MONITOR_COMMANDS.CLASS_STUDENTS_UPDATED) {
                this.reloadClass();
            } else if (data.cmd === MONITOR_COMMANDS.QUIZ_ANSWERED) {
                this.reloadClassDailyJuicesScores();
            } else if (data.cmd === MONITOR_COMMANDS.ASSIGNMENT_ANSWERED) {
                this.reloadClassAssignmentsScores();
            } else if (data.cmd === MONITOR_COMMANDS.STUDENT_UPDATE_ACHIEVEMENT) {
                this.reloadClassStudentAchievements();
            } else if (data.cmd === MONITOR_COMMANDS.USER_EMAIL_BAD_DETECTED) {
                this.setUserBadEmail();
            } else if (data.cmd === MONITOR_COMMANDS.STORY_RATING_UPDATED) {
                this.setStoryRating(data.payload || {});
            } else if (data.cmd === MONITOR_COMMANDS.EXTRA_JUICE_RATING_UPDATED) {
                this.setExtraJuiceRating(data.payload || {});
            } else {
                // eslint-disable-next-line no-console
                console.log("[WS]: Unknown command ", data);
            }
        },

        onUpdateAll() {
            this.reloadDashboard();
            this.reloadClass();
        },
    };
}

export default {
    getTeacherCallbacks,
};
