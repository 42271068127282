const MONITOR_COMMANDS = {
    USER_EMAIL_BAD_DETECTED: "user_email_bad_detected",
    DJ_IN_PROGRESS: "daily_juice_in_progress",
    DJ_COMPLETED: "daily_juice_completed",
    CLASS_STUDENTS_UPDATED: "class_students_updated",
    QUIZ_ANSWERED: "quiz_answered",
    ASSIGNMENT_ANSWERED: "assignment_answered",
    STUDENT_UPDATE_ACHIEVEMENT: "student_update_achievement",
    STORY_RATING_UPDATED: "story_rating_updated",
    EXTRA_JUICE_RATING_UPDATED: "extra_juice_rating_updated"
};

const getMonitorCommands = () => {
    return { ...MONITOR_COMMANDS };
};

export default {
    getMonitorCommands,
};
